import { MerchantInfo, MerchantInfoContext, useUser } from '@src/common';
import { RouteGuard } from '@src/components/auth-guard';
import { CommonHeader as Header } from '@src/components/header';
import { PageNotFound } from '@src/components/page-not-found';
import { useSessionStorageState } from 'ahooks';
import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { PAGE_ROUTES } from './config';
import { RouteRedirectConfig } from './types';
const Footer = lazy(() => import('@src/components/footer/CommonFooter'));

const CommonRoute = ({
  component,
  noAuth,
  fallback,
  isShowNav = true,
}: {
  component: React.ReactNode;
  fallback: React.ReactChild;
  noAuth?: boolean;
  isShowNav?: boolean;
}) => {
  const [info, setInfo] = useSessionStorageState<MerchantInfo['info']>('MERCHANT_INFO', {});
  const navigate = useNavigate();
  const { merchInfo } = useUser();
  useEffect(() => {
    if (merchInfo) {
      setInfo(merchInfo);
    }

    // 没签约过的老实去签约 :)
    if (merchInfo?.mchIsSigned === false) {
      navigate('/merchants/account-sign');
    }
  }, [merchInfo]);

  return (
    <MerchantInfoContext.Provider value={{ info, setInfo }}>
      {merchInfo && (
        <>
          <Header isShowNav={isShowNav} />
          <Suspense fallback={fallback}>
            {noAuth ? (
              <>
                {component}
                <Footer />
              </>
            ) : (
              <RouteGuard>
                {component}
                <Footer />
              </RouteGuard>
            )}
          </Suspense>
        </>
      )}
    </MerchantInfoContext.Provider>
  );
};

class PageRoutes extends React.Component {
  renderLoading() {
    return (
      <Spin
        style={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%)',
        }}
        tip="Loading..."
      ></Spin>
    );
  }

  renderFullPageRoute({ component, path, noAuth }: { component: React.ReactNode; path: string; noAuth?: boolean }) {
    return (
      <Route
        key={path}
        path={path}
        element={
          <Suspense fallback={this.renderLoading()}>
            {noAuth ? component : <RouteGuard>{component}</RouteGuard>}
          </Suspense>
        }
      />
    );
  }

  renderCommonRoute({
    path,
    ...props
  }: {
    component: React.ReactNode;
    path: string;
    noAuth?: boolean;
    isShowNav?: boolean;
  }) {
    return <Route key={path} path={path} element={<CommonRoute {...props} fallback={this.renderLoading()} />} />;
  }

  renderRedirectRoute(route: RouteRedirectConfig) {
    return (
      <Route
        key={`${route.path}${route.index}${route.to}`}
        path={route.path}
        index={route.index}
        element={<Navigate to={route.to} replace={true} />}
      />
    );
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="merchants">
            {PAGE_ROUTES.fullPage.map((route) => this.renderFullPageRoute({ ...route }))}
            {PAGE_ROUTES.common.map((route) => this.renderCommonRoute({ ...route }))}
            {PAGE_ROUTES.redirect ? PAGE_ROUTES.redirect.map((route) => this.renderRedirectRoute(route)) : null}
          </Route>
          <Route index element={<Navigate to="merchants" replace={true} />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default PageRoutes;
