/** 0-正常，1-停用，2-测试 */
export enum MchState {
  Normal = 0,
  Stop = 1,
  Test = 2,
}

export enum MchType {
  Merchant = 0,
}

export const CSRF_TOKEN_KEY = 'WPE_CSRF_TOKEN';
