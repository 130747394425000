import './tailwind.css';

import { ConfigProvider } from 'antd';
import React from 'react';

import PageRoutes from './routes';

ConfigProvider.config({
  prefixCls: 'wxp',
});

const App = () => (
  <React.StrictMode>
    <ConfigProvider prefixCls="wxp">
      {/* 增加新页面请前往 `src/routes/config.tsx` 进行路由配置 */}
      <PageRoutes />
    </ConfigProvider>
  </React.StrictMode>
);

export default App;
