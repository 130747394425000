import { checkIsLogin } from '@src/apis/merchant';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
export const RouteGuard = ({ children: RouteComponent }) => {
  const [isAuth, setAuth] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    void checkIsLogin().then((isLogin) => {
      if (!isLogin) {
        navigate('/merchants/login');
      }

      setAuth(isLogin);
    });
  }, [RouteComponent]);

  return <>{isAuth && RouteComponent} </>;
};
