import { CSRF_TOKEN_KEY } from '@src/common';
import { sha512 } from 'js-sha512';

import httpUtil from '../utils/http-util';
import { MERCH_ACTION } from './constant';

export interface MerchInfo {
  wpMchid: string;
  wxMchid: string;
  mchName: string;
  mchRemark: string;
  companyName: string;
  categoryMetaId: number;
  mchCountryCode: string;
  mchType: number;
  mchCurrency: string;
  mchAllowedTransaction: boolean;
  mchState: number;
  spid: string;
  registerAddressFull: string;
  ciNumber: string;
  mchIsSigned: boolean;
}

export function login(ticket: string, randstr: string, pwd: string, wpMchid: string) {
  const sha512Pwd = sha512.create().update(pwd).hex();
  return httpUtil.post<{ token: string }>(
    MERCH_ACTION.LOGIN,
    { ticket, randstr, pwd: sha512Pwd, wpMchid },
    'Login Success',
  );
}

export function checkIsLogin() {
  return httpUtil.get<boolean>(MERCH_ACTION.CHECK_IS_LOGIN, {});
}

export function getMerchInfo() {
  return httpUtil.get<MerchInfo>(MERCH_ACTION.GET_MECH_INFO, {});
}

export function changePassword(pwd: string, oldPwd) {
  const sha51OldPwd = sha512.create().update(oldPwd).hex();
  const sha51NewPwd = sha512.create().update(pwd).hex();
  return httpUtil.post<void>(
    MERCH_ACTION.CHANGE_PASSWORD,
    { pwd: sha51NewPwd, oldPwd: sha51OldPwd },
    'Change Password Success',
  );
}

export function sendResetPwdSms(wpMchid: string, cardNo: string, mobile: string) {
  return httpUtil.post<void>(MERCH_ACTION.SEND_RESET_SMS, { wpMchid, cardNo, mobile }, 'Send SMS Success');
}

export function verifyResetPwdMsg(wpMchid: string, cardNo: string, mobile: string, verifyCode: string) {
  return httpUtil.post<{}>(MERCH_ACTION.VERIFY_RESET_MSG, { wpMchid, cardNo, mobile, verifyCode });
}

export function resetPwd(wpMchid: string, pwd: string, emailCode: string) {
  const sha512Pwd = sha512.create().update(pwd).hex();
  return httpUtil.post<{}>(
    MERCH_ACTION.RESET_PASSWORD,
    { wpMchid, pwd: sha512Pwd, emailCode },
    'Reset Password Success',
  );
}

export function logout() {
  return httpUtil.post<void>(MERCH_ACTION.LOGOUT, {}, 'Logout Success').then(() => {
    localStorage.removeItem(CSRF_TOKEN_KEY);
  });
}
