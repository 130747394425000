import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { t } from './locales';

//  The time on this platform is recorded using Netherlands Time (GMT+1) as standard.
moment.tz.setDefault(t('timezone'));

ReactDOM.render(<App />, document.getElementById('root'));
