import EuropeLocale from './eu';
import SingaporeLocale from './sg';

type LocaleFile = typeof EuropeLocale;

const currentLocale = import.meta.env.VITE_REGION;

export const t = <T extends keyof LocaleFile>(key: T) => {
  switch (currentLocale) {
    case 'eu':
      return EuropeLocale[key];
    case 'sg':
      return SingaporeLocale[key];
    default:
      throw new Error('Illegal locale key');
  }
};
