/* eslint-disable unicorn/filename-case */

import { t } from '@src/locales';
import React, { lazy } from 'react';

import { PageRoutesConfig } from './types';

// 尽可能启用 lazyload，避免一次加载过多文件
const AccountSetting = lazy(() => import('../pages/account-setting'));
const OperationPassword = lazy(() => import('@src/pages/operation-password/OperationPassword'));
const Login = lazy(() => import('@src/pages/login'));
const OperationSensitiveGuidelines = lazy(
  () => import('@src/pages/operation-sensitive-guidelines/OperationSensitiveGuidelines'),
);
const Transaction = lazy(() => import('@src/pages/transaction'));

const SignAccount = lazy(() => import('@src/pages/account-sign'));

const AboutUs = lazy(() => import(`../pages/about-us/${t('AboutUs')}.tsx`));
const Developers = lazy(() => import('@src/pages/developers/Developers'));
const CookiePolicy = lazy(() => import(`../pages/policy/${t('cookiePolicy')}.tsx`));
const PrivacyPolicy = lazy(() => import(`../pages/policy/${t('PrivacyPolicy')}.tsx`));
const FailedSmsCode = lazy(() => import('@src/pages/failed-sms-code'));

/**
 * 页面路由配置
 */
export const PAGE_ROUTES: PageRoutesConfig = {
  // 全屏页面，需自定义 Header 和 Footer
  fullPage: [
    { path: 'login', component: <Login />, noAuth: true },
    { path: 'about-us', component: <AboutUs />, noAuth: true },
    { path: 'developers', component: <Developers />, noAuth: true },
    { path: 'cookie-policy', component: <CookiePolicy />, noAuth: true },
    { path: 'privacy-policy', component: <PrivacyPolicy />, noAuth: true },
  ],

  // 普通页面，会自动渲染公共的 Header 和 Footer
  common: [
    { path: 'transaction/*', component: <Transaction /> },
    { path: 'account-setting/*', component: <AccountSetting /> },
    {
      path: 'operation-sensitive-guidelines',
      component: <OperationSensitiveGuidelines />,
    },
    { path: 'operation-password', component: <OperationPassword /> },
    { path: 'failed-sms-code', component: <FailedSmsCode /> },
    { path: 'account-sign/*', component: <SignAccount />, isShowNav: false },
  ],

  // 重定向配置
  redirect: [{ index: true, to: 'account-setting' }],
};
