
      window.pageEnv = import.meta.env.VITE_ENV;
      if (Aegis) {
        var aegisEnv = Aegis.environment || {};
        try {
          var env;
          switch (window.pageEnv) {
            case 'test':
              env = aegisEnv.test;
              break;
            case 'prod':
              env = aegisEnv.production;
              break;
            default:
              // 没获取到pageEnv且chrome大于63版的chrome认为是开发者
              if (
                (
                  (navigator.userAgent
                    .toLowerCase()
                    .match(/chrome\/([\d\.]+)/) || [])[1] || ''
                ).match(/\d+/) > 63
              ) {
                env = aegisEnv.local || 'local';
                // 如果是开发环境就不设置aegis，目前aegis在local环境仍然会告警
              } else {
                env = aegisEnv.production || 'production';
              }
              break;
          }
        } catch (e) {
          console.error('[aegis load error]', e);
        }
        if (env === aegisEnv.production) {
          window.aegis = new Aegis({
            id: 'qdIINLufkkcvvNhYnY', // 项目ID，即上报id
            uin: 'none', // 用户唯一 ID（可选）
            reportApiSpeed: true, // 接口测速
            reportAssetSpeed: true, // 静态资源测速
            spa: true,
            env: env,
            beforeReport: function (log) {
              console.info('[aegis error]', log);
              return log;
            },
            api: {
              apiDetail: true,
            },
            afterRequest: function (data) {
              // {isErr: false, result: Array(1), logType: "log", logs: Array(4)}

              var logs = (
                data.logs
                  ? Array.isArray(data.logs)
                    ? data.logs
                    : [data.logs]
                  : []
              ).filter(function (item) {
                return (
                  (item.msg || '').indexOf('olmmdata/tam_transmit') < 0 &&
                  (item.msg || '').indexOf('aegis.qq.com') < 0
                );
              });
              if (
                ['event', 'log'].indexOf(data.logType) < 0 ||
                logs.length === 0
              ) {
                return;
              }

              var xhr = new XMLHttpRequest();
              xhr.open(
                'POST',
                'https://pay.weixin.qq.com/xdc/olmmdata/tam_transmit',
                true
              );
              xhr.setRequestHeader('Content-Type', 'application/json');

              var requestData = {
                bean: aegis.bean,
                logType: data.logType,
                logs,
              };
              xhr.send(JSON.stringify(requestData));
            },
          });
        }
      }
    