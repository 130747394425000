import './index.less';

import { useLogout, useMerchantInfo } from '@src/common';
import { t } from '@src/locales';
import React from 'react';
import { NavLink } from 'react-router-dom';
export const CommonHeader = ({ isShowNav = true }: { isShowNav?: boolean }) => {
  const { logout } = useLogout();
  const { info: { wpMchid } = {} } = useMerchantInfo();

  return (
    <div className="header comm-header" id="anchor-gotop">
      <div className="user-header">
        <p>Hi, {wpMchid}</p>
        <p>
          <a onClick={() => logout()}>Sign out</a>
        </p>
      </div>
      <div className="wrap">
        <NavLink to="/">
          <img className="logo" src={t('logoUrl')} />
        </NavLink>
        {isShowNav && (
          <div className="nav">
            <ul>
              <li className="nav_1">
                <NavLink
                  style={({ isActive }) => ({
                    borderBottom: isActive ? `4px solid #44b549` : 'none',
                  })}
                  to="/merchants/account-setting"
                >
                  Account Setting
                </NavLink>
              </li>
              <li className="nav_0">
                <NavLink
                  style={({ isActive }) => ({
                    borderBottom: isActive ? '4px solid #44b549' : 'none',
                  })}
                  to="/merchants/transaction"
                >
                  Transaction
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonHeader;
