import { createContext, Dispatch, SetStateAction } from 'react';

import { MchState, MchType } from '..';

export interface MerchantInfo {
  info?: {
    categoryMetaId: number;
    ciNumber: string;
    companyName: string;
    mchAllowedTransaction: boolean;
    mchCountryCode: string;
    mchCurrency: string;
    mchIsSigned: boolean;
    mchName: string;
    mchState: MchState;
    mchType: MchType;
    wpMchid: string;
    wxMchid: string;
  };
  setInfo?: Dispatch<SetStateAction<this['info']>>;
}

export const MerchantInfoContext = createContext<MerchantInfo>({});
