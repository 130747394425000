export const ACCOUNT_SETTING = {
  UPLOAD_MECH_CERT: 'api/mch/uploadMchCert',
  DOWNLOAD_MECH_CERT: 'api/mch/downloadMchCert',
  GET_MECH_CERT: 'api/mch/getMchCert',
  GET_MECH_API_KEY: 'api/mch/getApiKey',
  UPLOAD_MECH_API_KEY: 'api/mch/setApiKey',
};

export const MERCH_ACTION = {
  LOGIN: 'api/mch/login',
  CHECK_IS_LOGIN: 'api/mch/is_login',
  GET_MECH_INFO: 'api/mch/getInfo',
  CHANGE_PASSWORD: 'api/mch/password',
  LOGOUT: 'api/mch/logout',
  SEND_RESET_SMS: 'api/mch/send_reset_pwd_sms',
  VERIFY_RESET_MSG: 'api/mch/verify_reset_pwd_msg',
  RESET_PASSWORD: 'api/mch/reset_pwd',
};

export const TRANSACTION_API = {
  QUERY_ORDERS_BY_TIME: 'api/transaction/query_orders_by_time',
  QUERY_ORDER_BY_NO: 'api/transaction/query_order_by_no',
  QUERY_REFUNDS_BY_TIME: 'api/transaction/query_refunds_by_time',
  QUERY_REFUNDS_BY_ORDER_NO: 'api/transaction/query_refunds_by_order_no',
  QUERY_REFUND_BY_REFUND_NO: 'api/transaction/query_refund_by_refund_no',
};

export const ACCOUNT_API = {
  GET_ACCOUNT_SIGN_INFO: 'api/mch/get_account_sign_info',
  SIGN_ACCOUNT: 'api/mch/sign_account',
};

export const PIN_API = {
  CHECK_PIN: 'api/mch/check_pin',
  GET_MOBILE: 'api/mch/get_mobile',
  SEND_SMS: 'api/mch/send_pin_sms',
  SET_PIN: 'api/mch/set_pin',
  RESET_PIN: 'api/mch/reset_pin',
  VERIFY_PIN: 'api/mch/verify_pin',
  CHECK_PIN_VERIFY: 'api/mch/check_pin_verify',
};
