import Logo from '../images/logo.eu.svg';

export default {
  brand: 'WeChat Pay Europe',
  logoUrl: Logo,
  cookiePolicy: 'CookiePolicy',
  PrivacyPolicy: 'PrivacyPolicy',
  AboutUs: 'AboutUs',
  Date: '2021',
  timezone: 'CET',
  agreementDownloadLink: 'https://wpe-static-1306330437.cos.eu-frankfurt.myqcloud.com/sign/protocol/WeChat%20Payment%20Service%20Agreement%20-%20WPEU%20and%20Merchant%20-%2020220530.pdf',
} as const;
